
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { giftList, getLedgerAll, addGift, delGift, editGift, giftListExcel } from "@/api/request/item";

//组件
@Component({
  name: "GiftSet",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //表单数据
  private form: any = {
    type: 2,
    name: "",
    reg_time: "",
    code_type: 0,
    id: undefined,
    code: undefined,
    use_num: undefined,
    expire_time: undefined,
    children: [
      {
        name: "",
        remark: "",
        props: [
          {
            prop_name: "",
            prop_num: undefined,
          },
        ],
      },
    ],
  };

  //表单规则
  private rules: any = {
    code: [{ required: true, message: "请输入礼包码", trigger: "blur" }],
    name: [{ required: true, message: "请输入礼包名称", trigger: "blur" }],
    use_num: [{ required: true, message: "请输入使用次数", trigger: "blur" }],
    code_num: [{ required: true, message: "请输入生成个数", trigger: "blur" }],
    expire_time: [{ required: true, message: "请输入礼包有效期", trigger: "blur" }],
  };

  //请求数据
  private listQuery: any = {
    //页码
    row: 20,
    page: 1,

    //数据
    type: 0,
    code: "",
    excel: false,
    code_batch: "",
  };

  //类型列表
  private typeList: any[] = [
    { id: 0, name: "全部" },
    { id: 1, name: "正常 " },
    { id: 2, name: "礼包码 " },
    { id: 3, name: "批量礼包码" },
  ];

  //定义变量
  private list: any[] = []; //数据
  private total: number = 0; //总数
  private propOptions: any[] = []; //道具数据
  private listLoading: boolean = true; // 列表加载
  private dialogShow: boolean = false; //显示对话框

  //创建调用
  created() {
    //获取列表
    this.getList();
  }

  ////获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = false;

    //获取数据
    const { data } = await giftList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //获取道具数据
    const res = await getLedgerAll();

    //数据赋值
    this.propOptions = res.data;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //添加礼包
  private addGift(): void {
    //数据赋值
    this.form.children.push({
      name: "",
      remark: "",
      props: [
        {
          prop_name: "",
          prop_num: undefined,
        },
      ],
    });
  }

  //取消按钮
  private btnCancel(): void {
    //清空表单提示
    (this.$refs.ruleForm as any).clearValidate();

    //隐藏界面
    this.dialogShow = false;

    //重置对话框
    this.resetDialog();

    //获取列表
    this.getList();
  }

  //添加礼包
  private addHandler(): void {
    //数据赋值
    this.form = {
      type: 2,
      name: "",
      reg_time: "",
      code_type: 0,
      id: undefined,
      code: undefined,
      use_num: undefined,
      expire_time: undefined,
      children: [
        {
          name: "",
          remark: "",
          props: [
            {
              prop_name: "",
              prop_num: undefined,
            },
          ],
        },
      ],
    };

    //显示界面
    this.dialogShow = true;
  }

  //保存礼包
  private handlePush(): void {
    //表单监测
    (this.$refs.ruleForm as any).validate(async (valid: any) => {
      if (valid && this.isAllFull()) {
        //正常礼包
        if (this.form.type === 1) {
          this.form.code = undefined;
          this.form.use_num = undefined;
        }

        //增加礼包
        if (!this.form.id) {
          //添加礼包
          const res = await addGift(this.form);

          //显示提示
          this.$message.success("新增礼包成功");
        }
        //编辑礼包
        else {
          //编辑礼包
          const res = await editGift(this.form);

          //显示提示
          this.$message.success("编辑礼包成功");
        }

        //隐藏界面
        this.dialogShow = false;

        //重置对话框
        this.resetDialog();

        //获取数据
        this.getList();
      } else {
        return false;
      }
    });
  }

  //重置对话框
  private resetDialog(): void {
    this.form = {
      type: 2,
      name: "",
      id: undefined,
      code: undefined,
      use_num: undefined,
      expire_time: undefined,
      children: [
        {
          name: "",
          remark: "",
          props: [
            {
              prop_name: "",
              prop_num: undefined,
            },
          ],
        },
      ],
    };
  }

  //是否全部填写
  private isAllFull(): boolean {
    //定义变量
    let status: boolean = true;

    //数据赋值
    this.form.children.forEach((item: any) => {
      item.props.forEach((item2: any) => {
        if (!item2.prop_num || !item2.prop_id) {
          this.$message.error("礼包列表中有空项");
          return (status = false);
        }
      });
    });

    //返回结果
    return status;
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //导出处理
  private async exportHandler() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = true;

    //获取数据
    const { data } = await giftListExcel(this.listQuery);

    //保存数据
    saveAs(data, "礼包详情");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //删除礼包
  private delProp(i1: any): void {
    this.form.children.splice(i1, 1);
  }

  //添加道具
  private addProp(i1: any): void {
    //数据赋值
    this.form.children[i1].props.push({
      prop_name: "",
      prop_num: undefined,
    });
  }

  //处理删除
  private handleDel(id: any): void {
    //显示提示
    this.$confirm(`您确定要删除该礼品？`, "警告", {
      confirmButtonText: this.$t("permission.confirm") as string,
      cancelButtonText: this.$t("permission.cancel") as string,
      type: "warning",
    })
      .then(async () => {
        //删除礼包
        const res = await delGift({ id });

        //重新获取列表
        this.getList();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //处理编辑编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.form = row;

    //显示对话框
    this.dialogShow = true;
  }

  //删除礼包
  private delPropRow(i1: any, i2: any): void {
    //数据赋值
    this.form.children[i1].props.splice(i2, 1);
  }
}
